export default function DataCollection() {
    return (
        <>
            <div className="mx-10 my-10">
                <strong>Data Collection</strong>
                <p>
                    <br />
                    1. Your phone number:
                    <br />
                    Your phone number is only used by Spotdot for two purposes:
                    <br />
                    a. For login purposes.
                    <br />
                    b. As a communication medium for purposes including but not limited to alerts, support etc.
                    <br /><br />
                    2. Your name:
                    <br />
                    Your name helps people in your organizations to recognize you. At the moment, only organization admins can see your name. You can change your name at any time.<br />
                </p>

            </div>
        </>
    )
}