import { useState, useEffect } from 'react';
import TextTransition, { presets } from "react-text-transition";
import Styled from './Banner.styled';

const TEXTS = [
  "company",
  "studio",
  "store",
  "people"
];

const COLORS = [
  '#41B3FD',
  '#BA77D9',
  '#77D9B6',
  '#B23A3A'
]

const Banner = () => {
  const [index, setIndex] = useState(0);

  const colors = {
    color: COLORS[index % COLORS.length],
  }

  useEffect(() => {
    const intervalId = setInterval(() =>
      setIndex(index => index += 1),
      3000 // every 3 seconds
    );
    return () => clearTimeout(intervalId);
  }, []);
  return (
    <Styled.BannerWpr>
      <Styled.BoxHeading>
        <Styled.Heading>
          secure contactless experiences<Styled.Transition> for your&nbsp;<Styled.Animation> <TextTransition style={colors} springConfig={presets.stiff}>
            {TEXTS[index % TEXTS.length]}
          </TextTransition>
          </Styled.Animation>
          </Styled.Transition>
        </Styled.Heading>
        <Styled.DescHeadingOne>
          Contactless employee attendance platform for businesses and organizations. <br />
          Available soon.
        </Styled.DescHeadingOne>
        <Styled.DescHeadingOne>
          Email: contact@spotdot.in <br />
          Phone no: +91 70152 30859 <br />
          Spotdot Technologies Private Limited
        </Styled.DescHeadingOne>
      </Styled.BoxHeading>
    </Styled.BannerWpr>
  );
};

export default Banner;
