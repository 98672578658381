//? ESSENTIAL IMPORTS
import { Routes, Route } from 'react-router-dom';

//? COMPONENT IMPORTS
import Landing from './pages/Landing/LandingPage';
import AndroidClientTermsAndConditionsPage from './pages/Clients/Android/TermsAndConditionsPage';
import AndroidClientPrivacyPolicyPage from './pages/Clients/Android/PrivacyPolicyPage';
import AndroidClientDataCollectionPage from './pages/Clients/Android/DataCollectionPage';
import AndroidClientAppPermissionsPage from './pages/Clients/Android/AppPermissionsPage';
//? STYLES IMPORTS
import Styled from './App.styled';

const App = () => {
    return (
        <Styled.ThemeWrapper>
            <Routes>
                <Route path='/' element={<Landing />} />
                <Route path='/android_terms_and_conditions' element={<AndroidClientTermsAndConditionsPage />} />
                <Route path='/android_privacy_policy' element={<AndroidClientPrivacyPolicyPage />} />
                <Route path='/android_data_collection' element={<AndroidClientDataCollectionPage />} />
                <Route path='/android_app_permissions' element={<AndroidClientAppPermissionsPage />} />
            </Routes>
        </Styled.ThemeWrapper>
    );
};

export default App;