import styled from 'styled-components';

export const ThemeWrapper = styled.div`
	/* color: ${(props) => props.theme.color}; */
	/* background-color: ${(props) => props.theme.bgColor}; */
`;

const Styled = {
	ThemeWrapper,
};

export default Styled;
