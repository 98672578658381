import SpotdotLogo from '../../assets/spotdot_logo.png';

import {Navbar } from 'flowbite-react'

export default function Header(){
  return (
    <div className="container w-[100vw]">
      <Navbar className='w-[100vw]'>
        <div className='flex items-center justify-center container'>
          <div>
            <Navbar.Brand href="http://www.spotdot.in/">
              <img
                src={SpotdotLogo}
                className="h-10 w-auto"
                alt="Spotdot Logo"
              />
            </Navbar.Brand>
          </div>
        </div>
      </Navbar>
    </div>
  )
}